import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Base Coat & Adhesives`}</h1>
    <p>{`When considering the right adhesives to use on a particular project, a number of variables should be considered, including the substrate, the insulant type and the finial finish required.`}</p>
    <h4>{`Fibre Base`}</h4>
    <p>{`A versatile basecoat offering high levels of flexibility, improved tensile strength & anti-crack resistance.`}</p>
    <h4>{`UF Base`}</h4>
    <p>{`A basecoat with excellent workability qualities. Can be sprayed or applied using traditional methods. Ideal as a basecoat for thin coat acrylic finishes.`}</p>
    <h4>{`Bedding & Scrim Adhesive (HP12)`}</h4>
    <p>{`HP12 uses high polymer technology, creating a cost effective basecoat with enhanced adhesion for difficult substrates.`}</p>
    <p>{`HP12 is also widely used for its flexibility and tensile strength for thin coat systems. Incorporation of an alkali resistant fibre mesh may be required.`}</p>
    <h4>{`Bedding & Scrim Adhesive (HPX)`}</h4>
    <p>{`HPX is an ultra high-performance render incorporating high polymer technology and fibres, it is generally used on unusual substrates that require greater flexibility and adhesion.`}</p>
    <p>{`In addition HPX has proven to work exceptionally well with a wide range of building boards. Incorporation of an alkali resistant fibre mesh is usually required.`}</p>
    <h4>{`Bedding & Scrim Adhesive (K&A)`}</h4>
    <p>{`K&A adhesive is a mineral based, bonding and reinforcing filler with water repellent & vapour permeable properties.`}</p>
    <p>{`It is ideal for use on the HECK external wall insulation systems, using any type of insulation board. K&A’s flexibility and tensile strength is ideal for use with thin coat silicone & acrylic systems. Incorporation of an alkali resistant fibre mesh is always required.`}</p>
    <h4>{`Bedding & Scrim Adhesive (ZF)`}</h4>
    <p>{`A ready mixed, high-performance adhesive incorporating high polymer technology and fibres. Being a cement free paste, ZF is easy to apply, highly flexible, extremely fine grained and offers outstanding adhesion.`}</p>
    <p>{`Suitable for use on the HECK external wall insulation system (using EPS insulation boards only) and is an ideal product for use with thin coat silicone & acrylic systems. Incorporation of an alkali resistant fibre mesh is always required.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      